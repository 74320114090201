<template>
  <div class="detail-container">
    <div class="detail-info">
      <div style="position: relative; min-height: calc(100% - 60px);">
        <van-cell-group class="bottom-dashed">
          <van-cell :title="`订单号： ${orderInfo.orderNo}`">
          </van-cell>
        </van-cell-group>
        <div class="bottom-dashed" style="padding-bottom:1rem;">
          <div class="productCard"  v-for="(item, index) in orderInfo.dishDetailMsgList" :key="index">
            <div class="productInfo">
              <div class="iconImg">
                <!-- <img
                  :src="item.files.length>0?item.files[0].fileUrl:''"
                  alt=""
                  style="width: 77px; height: 77px; border-radius: 5px;"
                /> -->
                <img
                  :src="item.uploadFileList && item.uploadFileList.length > 0 ? item.uploadFileList[0].fileUrl : '' " 
                  alt=""
                  style="width: 77px; height: 77px; border-radius: 5px;"
                />
              </div>
              <div class="titleLeft">
                <div class="tiL">{{ item.dishName }}</div>
                <div class="info">￥ {{ item.dishPrice }}</div>
                <div class="info">x{{ item.dishNum }}</div>
              </div>
            </div>
            <div class="priceRight">
              <div>￥ {{ item.dishTotalPrice }}</div>
            </div>
          </div>
        </div>
        <van-cell-group class="orderVo">
          <van-cell title="总计：" :value="`¥   ${orderInfo.sumPrice}`"/>
          <van-cell title="预订日期：" :value=orderInfo.targetDate />
          <van-cell title="餐次：" :value=orderInfo.fareName />
          <van-cell title="下单时间：" :value=orderInfo.orderTime />
          <van-cell title="订单状态：" :value=orderInfo.orderStatus />
        </van-cell-group>
        <div class="bottom-info">
          <!-- <div>
            <p style="text-align:center; font-weight: bold; color: #F99502;">营养信息</p>
            <van-cell-group class="nutritionInfo">
              <van-cell class="bottom-title" title="营养成分" value="含量"/>
              <van-cell v-for="(item, index) in nutritionVo" :key="index" :title="item.nutritionName" :value="item.nutritionContent"/>
            </van-cell-group>
          </div> -->
          <div style="margin: 8px 0;">
            <span style="color: #939191;">
              底部无更多数据~
            </span>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="page-bottom-btn">
      <van-button type="primary" size="large" @click="goBack">返回</van-button>
    </div> -->
  </div>
</template>

<script>
export default {
  name: "orderDetail",
  data() {
    return {
      orderNo: "",
      orderInfo: {},
      // nutritionVo:[],//营养信息
    };
  },
  watch: {
    
  },
  created() {
    this.orderNo = this.$route.query.orderNo;
    this.initData();
  },
  methods: {
    initData() {
      // this.nutritionVo = [
      //   {
      //     nutritionName: "热量（KJ）",
      //     nutritionContent: "1850",
      //   },
      //   {
      //     nutritionName: "蛋白质（g）",
      //     nutritionContent: "50",
      //   },
      //   {
      //     nutritionName: "脂肪（g）",
      //     nutritionContent: "23",
      //   },
      //   {
      //     nutritionName: "碳水化合物（g）",
      //     nutritionContent: "237",
      //   },
      // ]
      this.$http
        .getAction('/order/orderDetailQuery', {
          orderNo: this.orderNo, 
        })
        .then((res) => {
          if(res.code==200){
            this.orderInfo = res.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // goBack() {
    //   this.$router.go(-1);
    // },
  },
}
</script>

<style lang="less" scoped>
.detail-container{
  height: 100vh;
  background-color: #FFFFFF;
  .detail-info{
    // height: calc(100% - 88px);
    // overflow-x: scroll;
    padding: 0px 9px;
  }
  .productCard {
    display: flex;
    padding: 1rem 1.5rem 0;
    justify-content: space-between;
    .productInfo{
      display: flex;
      .iconImg {
        display: flex;
        justify-content: space-around;
        align-items: center;
      }
      .titleLeft {
        margin-left: 18px;
        .tiL{
          font-size: 15px;
          font-weight: 600;
          padding-bottom: 5px;
        }
        .info {
          font-size: 11px;
          font-weight: 400;
          margin-top: 5px;
        }
      }
    }
    .priceRight {
      font-size: 15px;
      font-weight: 350;
      text-align: right;
    }
  }
  .bottom-info{
    text-align: center;
    padding: 0 20px;
    // position: absolute;
    // bottom: -60px;
    // width: 100%;
    .van-cell__value{
      text-align: center;
    }
    .bottom-title{
      border-top: 1px solid #000000;
      font-weight: 700;
      // .van-cell__value{
      //   color: #323233;
      // }
    }
    .van-cell{
      padding: 0.5rem 1rem;
      border-bottom: 1px solid #939191;
    }
  }
}
.bottom-dashed{
  border-bottom: 0.5px dashed #D8D8D8 !important;
}
.van-cell::after, .van-hairline--top-bottom::after, .van-hairline-unset--top-bottom::after{
  border: none;
}
.page-bottom-btn{
  /deep/ .van-button--large {
    border-radius: 0.5rem;
  }
}
</style>